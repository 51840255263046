import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import { checkToken } from "@/api/login"
import { ACCESS_TOKEN, USER_NAME, USER_INFO, USER_AUTH, SYS_BUTTON_AUTH, UI_CACHE_DB_DICT_DATA, TENANT_ID, CACHE_INCLUDED_ROUTES } from "@/store/mutation-types"

import request from "@/request";

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      isAuth: true,
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
  },
  // 地图界面的报警
  {
    path: '/map/:page?',
    name: 'map',
    component: () => import('@/views/map/index.vue'),
  },


  {
    path: '/stair/:page?',
    name: 'stair',
    component: () => import('@/views/stair/index.vue'),

  },
  // //-------电一级页面--------
  // {
  //   path: '/electricity',
  //   name: 'electricity',
  //   component: () => import('@/views/stair/electricity/index.vue'),
  // },
  // //-------碳一级页面--------
  // {
  //   path: '/carbon',
  //   name: 'carbon',
  //   component: () => import('@/views/stair/carbon/index.vue'),
  // },
   //-------制冷机房(蓄冷、空气源)页面--------
  {
    path: '/accumulation',
    name: 'accumulation',
    component: () => import('@/views/secondLevel/accumulation/index.vue'),
  },
  //-------供冷二级页面--------
  {
    path: '/coolingAddition',
    name: 'coolingAddition',
    component: () => import('@/views/secondLevel/coolingAddition/index.vue'),
  },
  //-------市政供热二级页面--------
  {
    path: '/cityHeatAddition',
    name: 'cityHeatAddition',
    component: () => import('@/views/secondLevel/cityHeatAddition/index.vue'),
  },
  //-------供热(市政、锅炉、空气源)二级页面--------
  {
    path: '/heatAddition',
    name: 'heatAddition',
    component: () => import('@/views/secondLevel/heatAddition/index.vue'),
  },
  //-------生活热水二级页面--------
  {
    path: '/liveHotWater',
    name: 'liveHotWater',
    component: () => import('@/views/secondLevel/liveHotWater/index.vue'),
  },
  //-------中深层二级页面--------
  {
    path: '/zsc',
    name: 'zsc',
    component: () => import('@/views/secondLevel/zsc/index.vue'),
  },


  //-------中深层二级页面--------
  {
    path: '/zsc-hrx',
    name: 'zsc-hrx',
    component: () => import('@/views/secondLevel/zsc-hrx/index.vue'),
  },

  //-------光伏二级页面--------
  {
    path: '/photovoltaic',
    name: 'photovoltaic',
    component: () => import('@/views/secondLevel/photovoltaic/index.vue'),
  },



  {
    path: '/monitoring',
    name: 'monitoring',
    component: () => import('@/components/monitoring.vue')
  },
  {
    path: '*',
    name: 'notfund',
    component: () => import('@/views/NotFund.vue')
  },


]

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },

})


router.beforeEach((to, from, next) => {
  if(!localStorage.getItem('token')){
    localStorage.removeItem('token');
  }
  const urlParams = new URLSearchParams(window.location.search);
  const accessToken = urlParams.get('X-Access-Token');
  if (accessToken) {
    //调用token校验接口
    const accToken = {
      'accessToken': accessToken
    }
    request({
      url: '/sys/checkToken',
      method: 'post',
      data: accToken
    }).then(res => {
      if (res.code == '200') {
        let userInfo = {
          userImg: res.result.userInfo.avatar,
          userName: res.result.userInfo.realname,
          // userEnterprise: res.result.departs[0].departName
        }
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo))
        //存入id
        const pointerId = urlParams.get('pointerId');
        sessionStorage.setItem('pointerId', pointerId)
        urlParams.get('X-Access-Token');
        localStorage.setItem('token', res.result.token)

        const result = res.result
        // const userInfo = result.userInfo
        Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
        Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
        Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
        Vue.ls.set(UI_CACHE_DB_DICT_DATA, result.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
        Vue.ls.set('realname', userInfo.realname)


      }
      const token = localStorage.getItem('token');
      if (token || to.path === '/') {
        next(); // 放行
      } else {
        next({ path: '/' });
      }
      
    })
  } else {
    const token = localStorage.getItem('token');
    console.log(token,'token222222222222');

    if (token || to.path === '/') {
      next(); // 放行
    } else {
      next({ path: '/' });
    }
  }
});

export default router
